import React from "react";
import {Button} from "react-bootstrap";
import { Link } from "react-router-dom";
import "../../assets/css/style.css";
import BCA from "../../assets/img/store/bca.png";
import BNI from "../../assets/img/store/bni.png";
import BRI from "../../assets/img/store/bri.png";
import CIMB from "../../assets/img/store/cimbniaga.png";
import DANA from "../../assets/img/store/dana.png";
import DANAMON from "../../assets/img/store/danamon.png";
import GOPAY from "../../assets/img/store/gopay.png";
import LINKAJA from "../../assets/img/store/linkaja.png";
import MANDIRI from "../../assets/img/store/mandiri.png";
import PERMATA from "../../assets/img/store/permata.png";
import SHOPEE from "../../assets/img/store/shopeepay.png";
import QRIS from "../../assets/img/store/qris.png";
import SUGAR from "../../assets/img/Home/SUGAR.png";
import IDLE from "../../assets/img/Home/IDLE.png";


const games = [
  { id: "sugarpuffs", name: "SUGAR", image: SUGAR },
  { id: "idlemarkets", name: "IDLE", image: IDLE },
  {
    id: "jetpacks",
    name: "JET PACK",
    image: "https://storage.googleapis.com/nero-assets/nero-game-logo/JET.webp",
  },
  {
    id: "bitcoinclickers",
    name: "BITCOIN CLICKER",
    image:
      "https://storage.googleapis.com/nero-assets/nero-game-logo/BILLIONAIRE.webp",
  },
  {
    id: "warriorvsevil",
    name: "Warrior vs Evil",
    image: "https://storage.googleapis.com/nero-assets/home/man-vs-zombie-app.png",
  },
  {
    id: "ninjafruit",
    name: "Hayabusa Slash",
    image:
      "https://storage.googleapis.com/nero-assets/home/hayabusa-slash-app.png",
  },
  {
    id: "minicar",
    name: "Mini Car",
    image:
      "https://storage.googleapis.com/nero-assets/home/traffic-cars-game-square.jpg",
  },
  {
    id: "planettanks",
    name: "Planet Tanks",
    image:
      "https://storage.googleapis.com/nero-assets/home/tank-ambush-game-square.jpg",
  },
  {
    id: "roadfury",
    name: "Road Fury",
    image:
      "https://storage.googleapis.com/nero-assets/home/desert-run-small.jpg",
  },
  {
    id: "zombiedefense",
    name: "Zombie Defense",
    image:
      "https://storage.googleapis.com/nero-assets/home/zombie-defense-small.jpg",
  },
];

function ShopArea() {
  return (
    <section className="shop-area pt-120 pb-90">
      <div className="container bank">
        <div className="row">
          <div className="col-lg-6 col-sm-6">
            <div className="newsletter-form">
              <Button href="/top-up">
                <i className="fas fa-shopping-basket" /> Top Up Coin
              </Button>
            </div>
          </div>
          <div className="col-lg-6 col-sm-6">
            <div className="accessories-content">
              {/* <h5 style={{ textAlign: "right", color: "white" }}>
                Amount: Coin {amountLocalStrg}
              </h5> */}
              {/* <div className="newsletter-form">
              <button onClick={handleConvert} >
                <i  /> Convert Currency
              </button>
            </div> */}
              {/* <h5 style={{ textAlign: "right", color: "white", marginLeft: "20px" }}>
                  Converted Amount: {convertedAmount} Coins
                </h5> */}
            </div>
          </div>
        </div>
        <div className="bank-logo">
          <img src={BCA} alt="BCA" />
          <img src={BNI} alt="BNI" />
          <img src={BRI} alt="BRI" />
          <img src={CIMB} alt="CIMB" />
          <img src={DANA} alt="DANA" />
          <img src={DANAMON} alt="DANAMON" />
          <img src={GOPAY} alt="GOPAY" />
          <img src={LINKAJA} alt="LINKAJA" />
          <img src={MANDIRI} alt="MANDIRI" />
          <img src={PERMATA} alt="PERMATA" />
          <img src={QRIS} alt="QRIS" />
          <img src={SHOPEE} alt="SHOPEE" />
        </div>
      </div>
      <div className="Game-show">
        {games.map((game) => (
          <div key={game.id}>
            <Link to={`/shop/${game.id}`}>
              <img
                style={{ borderRadius: 12 }}
                src={game.image}
                alt={game.name}
              />
            </Link>
          </div>
        ))}
      </div>
    </section>
  );
}

export default ShopArea;
