import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import API from '../../../api'; // Adjust the path to your API utility
import { isAuthenticated } from '../../../utils/auth';
import Slider from 'react-slick'; // Adjust the path to your auth utility
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const gameNames = [
  "Mobile Legends",
  "Free Fire",
  "Honor of Kings",
  "Valorant",
  "PUBG Mobile (Indonesia)",
  "Call of Duty Mobile (Bind Garena)",
  "Genshin Impact",
  "Age of Empires Mobile"
];

const gameImages = {
  "Mobile Legends": "https://storage.googleapis.com/nero-assets/home/MOBA.webp",
  "Free Fire": "https://storage.googleapis.com/nero-assets/home/FREEFIRE.webp",
  "Honor of Kings": "https://storage.googleapis.com/nero-assets/home/HOK.webp",
  "Valorant": "https://storage.googleapis.com/nero-assets/home/VALO.webp",
  "PUBG Mobile (Indonesia)": "https://storage.googleapis.com/nero-assets/home/PUBG.webp",
  "Call of Duty Mobile (Bind Garena)": "https://storage.googleapis.com/nero-assets/home/CODM.webp",
  "Genshin Impact": "https://storage.googleapis.com/nero-assets/home/GENSHIN.webp",
  "Age of Empires Mobile": "https://storage.googleapis.com/nero-assets/home/AOE.webp"
};

const GamesArea = () => {
  const [products, setProducts] = useState([]);
  const history = useHistory();

  useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await API.get("retrieveDTU");
          console.log("API Response in GamesArea Component:", response);
          if (response.data.response.status === "200") {
            const filteredProducts = response.data.response.list_dtu.filter(product => 
              gameNames.includes(product.name)
            );
            console.log("Filtered Products:", filteredProducts);
            setProducts(filteredProducts);
          }
        } catch (error) {
          console.error("Error fetching products:", error);
        }
      };
      fetchData();
  }, []);

  const handleLinkClick = (event, productName) => {
   
      history.push(`/product/${productName}`);
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipe: true,
    touchMove: true,
    draggable: true,
    swipeToSlide: true,
    touchThreshold: 10,
    autoplay: true,        // Enable autoplay
    autoplaySpeed: 3000, 
    arrows:false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <section className="featured-game-area position-relative pt-115 pb-90">
      <div className="featured-game-bg" />
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-6 col-lg-8 w100">
            <div className="section-title text-center mb-70 home-content">
              <h2 className='h2-home text-black'>TOP UP MOBILE GAMES POPULER</h2>
              <p className='p-home'>Temukan game favoritemu dengan harga terbaik</p>
            </div>
          </div>
        </div>
        <Slider {...settings}>
        <div>
          <Link to="/top-up">
            <img
              className="banner-home"
              src="https://storage.googleapis.com/nero-assets/promo-banner/nero-promo-des-1212-2024.jpg"
              alt="Nero Promo Desember 12.12"
              style={{ width: '100%', borderRadius: '10px' }}
            />
          </Link>
        </div>
        <div>
          <Link to="/top-up">
            <img
              className="banner-home"
              src="https://storage.googleapis.com/nero-assets/promo-banner/nero-promo-desember.jpg"
              alt="Nero Promo Desember"
              style={{ width: '100%', borderRadius: '10px' }}
            />
          </Link>
        </div>
        <div>
          <Link to="/top-up">
            <img
              className="banner-home"
              src="https://storage.googleapis.com/nero-assets/promo-banner/nero-promo-november.jpg"
              alt="Nero Promo November"
              style={{ width: '100%', borderRadius: '10px' }}
            />
          </Link>
        </div>
        <div>
          <Link to="/top-up">
            <img
              className="banner-home"
              src="https://storage.googleapis.com/nero-assets/promo-banner/nero-games-promo-oktober.jpg"
              alt="Nero Promo October"
              style={{ width: '100%', borderRadius: '10px' }}
            />
          </Link>
        </div>
        
        <div>
          <Link to="/top-up">
            <img
              className="banner-home"
              src="https://storage.googleapis.com/nero-assets/promo-banner/nero-promo-kemerdekaan-2024.jpg"
              alt="Nero Promo September"
              style={{ width: '100%', borderRadius: '10px' }}
            />
          </Link>
        </div>
        <div>
          <Link to="/top-up">
            <img
              className="banner-home"
              src="https://storage.googleapis.com/nero-assets/promo-banner/nero-promo-september.jpg"
              alt="Nero Promo September"
              style={{ width: '100%', borderRadius: '10px' }}
            />
          </Link>
        </div>
      </Slider>
        <div className="row featured-active justify-center">
          {gameNames.map((name, index) => (
            <div key={index} className="col-lg-4 col-sm-6 grid-item home-card-width">
              <div className="featured-game-item mb-30">
                <div className="featured-game-thumb">
                  <img src={gameImages[name]} alt={name || "Game Image"} />
                </div>
                <div className="featured-game-content featured-game-overlay-content">
                  <h4>
                    <Link
                      style={{ textDecoration: 'none' }}
                      to={`/product/${name}`}
                      onClick={(event) => handleLinkClick(event, name)}
                    >
                      <span style={{color:'white'}}>Top Up</span><span style={{color:'yellow', fontWeight:'bold', display:'block', marginTop:'10px'}}>Sekarang</span>
                    </Link>
                  </h4>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default GamesArea;
